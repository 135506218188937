const config = {
  name: 'Product Image Carousel',
  inputs: [
    {
      name: 'permalink',
      type: 'string',
      required: true,
      defaultValue: 'womens-organic-cotton-box-cut-pocket-tee-white',
    },
    {
      name: 'copy',
      type: 'text',
      defaultValue:
        'This is a dummy placeholder copy. Please, modify or leave it blank before publishing',
    },
    {
      name: 'colorPipsNumber',
      type: 'number',
      required: true,
      defaultValue: 5,
      helperText: 'Limit the number of colorways to be displayed',
    },
    {
      name: 'showInOverlay',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'backgroundColor',
      type: 'color',
      defaultValue: '#f3eeeb',
    },
    {
      name: 'hidePermalinks',
      type: 'longText',
      helperText: 'Write a permalink list like this: pemalink-1, permalink-2',
    },
  ],
}

export default config
